import React, { useState, useEffect } from "react";
import "twin.macro";

import dayjs from "dayjs";

import { Event } from "api/v2/event.types";

import { FormSection, Legend, Description } from "../styles";

import { Alert, Button, P } from "components/typography";
import Input from "components/forms/input";
import D2dMap from "./d2dMap";

const MILES_TO_METERS = 1609.34;
const DISTANCE_BUFFER = 0.5 * MILES_TO_METERS;
const DEFAULT_RANGE_IN_MILES = 3;

export const fieldsToAddress = (
  street: string,
  city: string,
  state: string,
  zip: string
) =>
  !(street && zip)
    ? "" // require street and zip, at least
    : [
        [street, city].filter((i) => i).join(", "),
        [state, zip].filter((i) => i).join(" ")
      ]
        .filter((i) => i)
        .join(", ");

const addressFieldNames = [
  "deliveryStreet",
  "deliveryCity",
  "deliveryState",
  "deliveryPostalCode"
];

const D2dFormSection: React.FC<{
  event: Event;
  register: any;
  errors: any;
  watch: any;
  trigger: any;
}> = ({ event, register, errors, watch, trigger }) => {
  const rangeInMiles = event?.d2dRadius || DEFAULT_RANGE_IN_MILES;
  const watchedAddress = watch(addressFieldNames);
  const [deliveryAddress, setDeliveryAddress] = useState(
    fieldsToAddress(
      watchedAddress.deliveryStreet,
      watchedAddress.deliveryCity,
      watchedAddress.deliveryState,
      watchedAddress.deliveryPostalCode
    )
  );
  const [deliveryDistance, setDeliveryDistance] = useState<
    number | undefined
  >();
  const validateDistance = () => {
    return (
      deliveryDistance === undefined ||
      deliveryDistance < rangeInMiles * MILES_TO_METERS + DISTANCE_BUFFER
    );
  };

  const processZip = (zip: React.ChangeEvent<HTMLInputElement>) => {
    if (zip.target.value.length == 5) {
      setDeliveryAddress(
        fieldsToAddress(
          watchedAddress.deliveryStreet,
          watchedAddress.deliveryCity,
          watchedAddress.deliveryState,
          zip.target.value
        )
      );
    }
  };

  useEffect(() => trigger(addressFieldNames), [deliveryDistance]);

  const coordinates = event.coordinates.map(({ lat, lng, ...rest }) => ({
    ...rest,
    lat: parseFloat(lat),
    lng: parseFloat(lng)
  }));

  const validationText = (
    <>
      Your address must be within {rangeInMiles} miles of our location
      {coordinates.length > 1 ? "s" : ""} to receive your order.
      <ul>
        {coordinates.map((c) => (
          <li key={c.street}>
            {c.street}, {c.cityStateZip}
          </li>
        ))}
      </ul>
    </>
  );

  return (
    <>
      <FormSection>
        <Description>
          <Legend>Delivery Location</Legend>
        </Description>

        <div tw="w-full md:w-3/4">
          <Alert.Info>{validationText}</Alert.Info>
          {event.pickupLat && event.pickupLng && (
            <div tw="py-6">
              <D2dMap
                radius={event.d2dRadius}
                coordinates={coordinates}
                lat={parseFloat(event.pickupLat)}
                lng={parseFloat(event.pickupLng)}
                fromAddress={`${event.pickupStreet}, ${event.pickupCityStateZip}`}
                deliveryAddress={deliveryAddress}
                setDeliveryDistance={setDeliveryDistance}
              />
            </div>
          )}
          <div tw="mt-2 flex flex-col gap-2">
            {deliveryDistance !== undefined &&
              (validateDistance() ? (
                <Alert.Success>
                  Your address is within our delivery range! We can't wait to
                  deliver you your amazing order.
                </Alert.Success>
              ) : (
                <Alert.Danger>
                  Your address is{" "}
                  {(
                    (deliveryDistance || 0) / MILES_TO_METERS -
                    rangeInMiles
                  ).toFixed(2)}{" "}
                  miles outside our delivery range. Sorry, we can't deliver your
                  order.
                </Alert.Danger>
              ))}
            <Input
              name={`deliveryStreet`}
              id={`deliveryStreet`}
              ref={register({
                required: "Must put in a street address",
                validate: {
                  distance: () =>
                    validateDistance() || "Address is outside of delivery range"
                }
              })}
              error={
                errors["deliveryStreet"] && errors["deliveryStreet"].message
              }
              type="text"
              tw="w-full"
              aria-label="Street"
              placeholder="Street"
              onBlur={(e) =>
                setDeliveryAddress(
                  fieldsToAddress(
                    e.target.value,
                    watchedAddress.deliveryCity,
                    watchedAddress.deliveryState,
                    watchedAddress.deliveryPostalCode
                  )
                )
              }
            />
            <div tw="flex flex-row gap-2">
              <Input
                name={`deliveryCity`}
                id={`deliveryCity`}
                ref={register({
                  required: "Must put in a city",
                  validate: {
                    distance: () =>
                      validateDistance() ||
                      "Address is outside of delivery range"
                  }
                })}
                error={errors["deliveryCity"] && errors["deliveryCity"].message}
                type="text"
                tw="w-full"
                aria-label="City"
                placeholder="City"
                onBlur={(e) =>
                  setDeliveryAddress(
                    fieldsToAddress(
                      watchedAddress.deliveryStreet,
                      e.target.value,
                      watchedAddress.deliveryState,
                      watchedAddress.deliveryPostalCode
                    )
                  )
                }
              />
              <Input
                name={`deliveryState`}
                id={`deliveryState`}
                ref={register({
                  required: "Must put in a state",
                  validate: {
                    distance: () =>
                      validateDistance() ||
                      "Address is outside of delivery range"
                  }
                })}
                error={
                  errors["deliveryState"] && errors["deliveryState"].message
                }
                type="text"
                tw="w-full"
                aria-label="State"
                placeholder="State"
                onBlur={(e) =>
                  setDeliveryAddress(
                    fieldsToAddress(
                      watchedAddress.deliveryStreet,
                      watchedAddress.deliveryCity,
                      e.target.value,
                      watchedAddress.deliveryPostalCode
                    )
                  )
                }
              />
              <Input
                name={`deliveryPostalCode`}
                id={`deliveryPostalCode`}
                ref={register({
                  required: "Must put in a zip code",
                  validate: {
                    distance: () =>
                      validateDistance() ||
                      "Address is outside of delivery range"
                  }
                })}
                error={
                  errors["deliveryPostalCode"] &&
                  errors["deliveryPostalCode"].message
                }
                type="text"
                tw="w-full"
                aria-label="Zip Code"
                placeholder="Zip Code"
                onChange={(e) => processZip(e)}
              />
            </div>
          </div>
          <div tw="flex flex-row gap-1 mt-2">
            <input
              id="default-address"
              name="saveDefaultAddress"
              type="checkbox"
              ref={register()}
              tw="accent-primary"
            />
            <P.Small as="label" htmlFor="default-address">
              Save as my default address
            </P.Small>
          </div>
        </div>
      </FormSection>
      <FormSection tw="md:items-center">
        <Description>
          <Legend>Delivery Time Window</Legend>
        </Description>
        <div tw="md:w-3/4">
          <Button.Primary tw="py-3 px-4" onClick={(e) => e.preventDefault()}>
            {dayjs(event.runAt).subtract(30, "minute").format("h:mm A")} -{" "}
            {dayjs(event.runAt).add(180, "minute").format("h:mm A")}
          </Button.Primary>
        </div>
      </FormSection>
    </>
  );
};

export default D2dFormSection;
